<template>
  <div class="admin-edit-shop-order" v-if="!loading">
    <div class="row">
      <div class=" col s4">
        <label for="user_name" class="device-label">Email Address</label>
        <div class="row">
          <input type="text" v-model="orderInternal.purchaser_email" class="text-field w-input w-75" disabled
                     maxlength="256" placeholder="Full Name" id="user_name">
          <i class="fa fa-external-link icon-button" @click="clickUser(orderInternal.user_id)"
             v-b-tooltip.hover title="View User Dialog"></i>
        </div>
      </div>
      <div class="col-4">
        <label for="order_number" class="device-label">Shopify Order Id</label>
        <input type="text" v-model="orderInternal.order_number" class="text-field w-input" disabled
               maxlength="256" name="Registration" data-name="Registration" placeholder="Stripe Product" id="order_number">
      </div>
      <div class="spacing"></div>
    </div>
    <div class="coupon-container">
      <div class="row">
        <label for="product_id" class="device-label">Ordered Products</label>
      </div>
      <div v-for="(product, idx) of orderInternal.products" v-bind:key="idx" class="coupon-item">
        <input type="text" v-model="orderInternal.products[idx]" class="text-field w-input" disabled
               maxlength="256" name="product" data-name="product" placeholder="Shopify Product" id="product_id">
        <i class="fa fa-external-link icon-button" @click="clickShopProduct(product)"
           v-b-tooltip.hover title="View Shop Product"></i>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        Last Updated: {{orderInternal.modified_datetime}}
      </div>
    </div>
    <div class="footer mt-2">
      <b-button class="button" @click="clickClose()">Close</b-button>
      <b-button class="button" @click="saveChanges()">Save Changes</b-button>
    </div>
  </div>
</template>

<script>
import * as DataProvider from '../helpers/DataProvider'
import * as ErrorHelper from '../helpers/ErrorHelper'
// import * as AlertHelper from '../helpers/AlertHelper'

export default {
  name: 'admin-edit-shop-order',
  components: {
  },
  props: {
    isModal: Boolean,
    modal: String,
    order: Object,
    orderId: String
  },
  data: function () {
    return {
      loading: true,
      showButtons: true,
      selectedDevices: null,
      orderInternal: {
        id: null,
        order_number: '',
        products: [],
        modified_datetime: ''
      }
    }
  },
  async created () {
    if (this.order) {
      this.updateOrder(this.order)
    } else if (this.orderId) {
      console.log('comp order id: ', this.orderId)
      this.loadOrder(this.orderId)
    }
    // Hide Controls so the parent modal can display them
    if (this.isModal) {
      this.showButtons = false
    }
    this.loading = false
  },
  methods: {
    loadOrder: async function (orderId) {
      let res = await DataProvider.adminGetShopOrder(orderId)
      console.log(res)
      if (res.success) {
        this.orderInternal = res.data
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    updateOrder: function (productData) {
      this.orderInternal = { ...productData } // Take a shallow copy to avoid editing the parent component's data directly
      // Note: If we don't at least take a shallow copy we can end up with unexpected behavior such as unsaved changes
      // being visible on the parent component, since JS passes by reference.
    },
    saveChanges: async function () {
      let res
      let coupons = Array.from(new Set(this.coupons.filter(x => x !== ''))) // Remove blank Items, use Set to remove dupes
      console.log(coupons)
      res = await DataProvider.adminUpdateShopProduct(
        this.orderInternal.id, { coupons })
      if (res.success) {
        console.log(res)
        this.updateOrder(res.data)
        this.$bvToast.toast('The changes you made have been saved!',
          {
            title: 'Changes Saved',
            variant: 'success',
            toaster: 'b-toaster-top-center'
          })
      } else {
        ErrorHelper.displayDataErrorToast(res)
      }
    },
    clickClose: async function () {
      console.log(this.orderInternal)
      this.$emit('save')
      // Check if we've been passed a modal to close.
      if (this.modal) {
        this.$bvModal.hide(this.modal)
      } else {
        this.$router.go(-1)
      }
    },
    changeSelection: function (newVal) {
      this.selectedDevices = newVal
    },
    clickShopProduct: function (product) {
      console.log(product)
      this.$router.push({ path: '/admin/shop-product/' + product })
    },
    clickUser: function (userId) {
      console.log(userId)
      this.$router.push({ path: '/admin/user/' + userId })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .admin-edit-shop-order {
    color: $text-color-invert;
  }

  .row {
    margin: 0;
  }

  .device-label{
    font-family: 'Open Sans', sans-serif;
    color: $theme-color-primary-3;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .device-code{
    color: $text-color-invert;
    margin-left: 5%;
  }

  .toolbar {
    display: flex;
    flex-direction: row;
  }

  .icon-button, .icon-button-danger{
    font-size: 1.9rem;
  }

  .footer{
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  .icon_select_color {
    border: solid $theme-color-primary-3;
  }

  .coupon-container {
    display: flex;
    flex-direction: column;
  }

  .coupon-item {
    display: flex;
    align-items: center;
    width: 50%;
    background: $theme-color-background-1;
    margin: 5px 10px;
    padding: 5px;
    border-radius: 10px;
  }

</style>
