var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c("div", { staticClass: "admin-edit-shop-order" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "user_name" } },
              [_vm._v("Email Address")]
            ),
            _c("div", { staticClass: "row" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.orderInternal.purchaser_email,
                    expression: "orderInternal.purchaser_email"
                  }
                ],
                staticClass: "text-field w-input w-75",
                attrs: {
                  type: "text",
                  disabled: "",
                  maxlength: "256",
                  placeholder: "Full Name",
                  id: "user_name"
                },
                domProps: { value: _vm.orderInternal.purchaser_email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.orderInternal,
                      "purchaser_email",
                      $event.target.value
                    )
                  }
                }
              }),
              _c("i", {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: { hover: true }
                  }
                ],
                staticClass: "fa fa-external-link icon-button",
                attrs: { title: "View User Dialog" },
                on: {
                  click: function($event) {
                    return _vm.clickUser(_vm.orderInternal.user_id)
                  }
                }
              })
            ])
          ]),
          _c("div", { staticClass: "col-4" }, [
            _c(
              "label",
              { staticClass: "device-label", attrs: { for: "order_number" } },
              [_vm._v("Shopify Order Id")]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.orderInternal.order_number,
                  expression: "orderInternal.order_number"
                }
              ],
              staticClass: "text-field w-input",
              attrs: {
                type: "text",
                disabled: "",
                maxlength: "256",
                name: "Registration",
                "data-name": "Registration",
                placeholder: "Stripe Product",
                id: "order_number"
              },
              domProps: { value: _vm.orderInternal.order_number },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.orderInternal,
                    "order_number",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c("div", { staticClass: "spacing" })
        ]),
        _c(
          "div",
          { staticClass: "coupon-container" },
          [
            _vm._m(0),
            _vm._l(_vm.orderInternal.products, function(product, idx) {
              return _c("div", { key: idx, staticClass: "coupon-item" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.orderInternal.products[idx],
                      expression: "orderInternal.products[idx]"
                    }
                  ],
                  staticClass: "text-field w-input",
                  attrs: {
                    type: "text",
                    disabled: "",
                    maxlength: "256",
                    name: "product",
                    "data-name": "product",
                    placeholder: "Shopify Product",
                    id: "product_id"
                  },
                  domProps: { value: _vm.orderInternal.products[idx] },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.orderInternal.products,
                        idx,
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("i", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover",
                      modifiers: { hover: true }
                    }
                  ],
                  staticClass: "fa fa-external-link icon-button",
                  attrs: { title: "View Shop Product" },
                  on: {
                    click: function($event) {
                      return _vm.clickShopProduct(product)
                    }
                  }
                })
              ])
            })
          ],
          2
        ),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col s12" }, [
            _vm._v(
              " Last Updated: " +
                _vm._s(_vm.orderInternal.modified_datetime) +
                " "
            )
          ])
        ]),
        _c(
          "div",
          { staticClass: "footer mt-2" },
          [
            _c(
              "b-button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.clickClose()
                  }
                }
              },
              [_vm._v("Close")]
            ),
            _c(
              "b-button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.saveChanges()
                  }
                }
              },
              [_vm._v("Save Changes")]
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "label",
        { staticClass: "device-label", attrs: { for: "product_id" } },
        [_vm._v("Ordered Products")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }